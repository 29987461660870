html{
  font-size: 62.5%;
}
body{
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Lucida Grande", Helvetica, Arial, "Microsoft YaHei", FreeSans, Arimo, "Droid Sans","wenquanyi micro hei","Hiragino Sans GB", "Hiragino Sans GB W3", Arial, sans-serif;
  *{
    box-sizing: border-box;
  }
}
ul,li{
  list-style: none;
  margin: 0;
  padding: 0;
  a{
    text-decoration: none;
  }
}
@for $i from 1 through 6 {
  h#{$i} {
    font-weight: normal;
    letter-spacing: 2px;
    margin: 0;
  }
}
.fr{
  float: right;
}
.fl{
  float: left;
}
.mt80{
  margin-top: 80px;
}
.wrap{
  position: relative;
  width: 1000px;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 25px;
}
header{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 80px;
  background: rgba(#fff,0);
  .logo,.webTitle{
    float: left;
  }
  .logo{
    width: 80px;
    margin: {
      //top:18px;
    };
  }
  .logo2{
    position: absolute;
    width: 80px;
    //top:18px;
    left: 25px;
    opacity: 0;
  }
  .webTitle{
    margin: {
      left: 30px;
    };
    font-size:2rem;
    color: #fff;
    line-height: 80px;
  }
  .topNav{
    float: right;
    //width: 300px;
    height: 80px;
    ul li{
      float: left;
      margin: {
        left:50px;
      };
      line-height: 80px;
      font-size:1.6rem;
      a{
        color: #fff;
      }
    }
  }
}
section#floorOne{
  position: relative;
  height: 850px;
  background: #000;
  margin: {
    top:-80px;
  };
  z-index: 1;
  overflow: hidden;
  video#BGVideo{
    width:100%;
    min-width:1920px;
    position: absolute;
    top:0;
    left: 0;
    z-index: -1;
  }
  .content{
    width: 445px;
    h1{
      font-size:6.1rem;
      margin:{
        top:240px;
        bottom:30px;
      };
      color:#fff;
      font-weight:lighter;
      //letter-spacing: 0.25rem;
    }
    small{
      font-size: 2.6rem;
      color: #fff;
    }
    .downloadBtn{
      a{
        display: block;
        width: 230px;
        height: 60px;
        &.ios{
          background: url("https://static001.youtoo365.com/web/img/official/button_iPhone.png")
        }
        &.android{
          margin: {
            top:40px
          };
          background: url("https://static001.youtoo365.com/web/img/official/button_Android.png")
        }
      }
      margin: {
        top:100px;
      };
    }
    img.ercode{
      margin: {
        top:100px;
        left:40px;
      };
    }
  }
}
section#floorTwo{
  position: relative;
  height: 850px;
  background: url("https://static001.youtoo365.com/web/img/official/bg.jpg") center top no-repeat;
  background-size: 100% 100%;
  color: #fff;
  text-align: center;
  .blockContent{
    width: 970px;
    margin:{
      left:-10px;
      top:80px;
    }
    overflow: hidden;
    .left{
      width: 270px;
      float: left;
    }
    .center{
      @extend .left;
      margin:0 10px 0 10px;
    }
    .right{
      width: 410px;
      height: 270px;
      float: left;
    }
    li{
      float: left;
    }
    .blockSmall{
      width: 130px;
      height: 130px;
    }
    .blockMiddle{
      width: 270px;
      height: 130px;
    }
    .blockLarge{
      width: 270px;
      height: 270px;
    }
    .block1,.block5,.block7{
      margin: {
        left:10px;
        bottom:10px;
      };
    }
    @for $i from 0 through 7{
      li.block#{$i}{
        background: url("https://static001.youtoo365.com/web/img/official/0#{$i}.jpg") center;
        &:before{
          content: "";
          display: none;
          background-image: url("https://static001.youtoo365.com/web/img/official/0#{$i}_hl.jpg");
        }
        &:hover{
          background: url("https://static001.youtoo365.com/web/img/official/0#{$i}_hl.jpg") center;
        }
      }
    }
  }
}
section#floorThree{
  background: #eee;
  position: relative;
  height: 900px;
  text-align: center;
  #animationContent{
    position: relative;
    width: 100%;
    height: 400px;
    margin: 50px auto;
    .radius{
      position: absolute;
      top: 0;
      left: 0;
      color: #888;
      font-weight: lighter;
      text-decoration: none;
      padding: 3px 6px;
      img{
        width: 100%;
      }
    }
    $centerData:(2,84px,82.5px),(3,100px,82.5px),(1,245px,233px);
    @each $i,$ml,$mt in $centerData{
      .center#{$i}{
        position: absolute;
        left: 50%;
        top:50%;
        margin-left: -$ml;
        margin-top: -$mt;
        z-index: $i;
        -webkit-backface-visibility:visible;
        -webkit-transform-origin:center center;
        -webkit-animation:centerRotate 8s 0s infinite linear normal none ;
        -moz-backface-visibility:visible;
        -moz-transform-origin:center center;
        -moz-animation:centerRotate 8s 0s infinite linear normal none ;
      }
    }
    @-webkit-keyframes centerRotate{
      0%{
        -webkit-transform:rotateZ(0deg);
      } 100%{
          -webkit-transform:rotateZ(360deg);
        }
    }
    @-moz-keyframes centerRotate{
         0%{
           -moz-transform:rotateZ(0deg);
         } 100%{
             -moz-transform:rotateZ(360deg);
           }
       }
  }
}
section.floor{
  h2.floorTitle{
    font-size: 4.8rem;
    font-weight:lighter;
    margin: {
      top:220px;
      bottom: 40px;
    }
  }
  p{
    font-size: 1.4rem;
    line-height: 14px;
  }
}
footer {
  height: 180px;
  padding: 40px 0 20px 0;
  position: relative;
  .erCodeWeixin,.erCodeWeibo {
    display: none;
    width: 200px;
    height: auto;
    position: absolute;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    border: solid 1px #eee;
    z-index: 9;
  }
  .topGroup{
    overflow: hidden;
    $footerMarginData: (iphone,100px),(android, 57px),(ercode,62px),(iconWeixin,70px,20px),(iconWeibo,20px,20px);
    @each $item, $ml,$mt in $footerMarginData {
      .#{$item} {
        float: left;
        margin:{
          top:$mt;
          left:$ml
        }
      }
    }
    .ercode {
      width: 70px;
    }
    .contact{
      color: #666;
      font-size: 1.4rem;
      line-height: 21px;
      float: left;
      width: 309px;
      margin-left: 70px;
    }
  }
  .copyright{
    width: 100%;
    text-align: center;
    margin: {
      top:30px;
      bottom:20px;
    };
    a{
      text-decoration:none;
      color: #000;
    }
  }
}